<template>
    <div class="row mb-3">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> MasterFeedback</h2>
                    </div>
                </div>            
            </div> 
            <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group icon-input mb-0">
                            <i class="ti-search font-xs text-grey-400"></i>
                            <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${cycle}`" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isLoad" class="col-lg-12">
            <div class="row mt-2" v-if="assignments.length">
                <div class="col-lg-8" v-if="current != null">
                    <div class="card rounded-lg border-0 py-2 px-3 mb-2">
                        <div class="row section full mt-2 mb-2">
                            <div class="col-lg-12 my-2">
                                <div class="col-lg-12 mb-4">
                                    <div class="row justify-content-between py-2">
                                        <div class="col-10 ">
                                            <h2 class="fw-700 font-md d-block">{{current.title}}</h2>
                                            <span class="font-xsss fw-600 text-grey-500 d-inline-block">{{current.due_date | formatDate}}</span>
                                        </div>
                                        <div class="col-2">
                                            <div v-if="current.submission_obj?.score != null" class="bg-success rounded-lg w-100 text-center py-2">
                                                <h1 class=" font-lg text-white fw-600">{{current.submission_obj.score | numfor}}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 text-center py-3" v-if="isLoadSubmissionAnswer">
                                            <div class="my-3">
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div class="spinner-grow text-warning mx-3" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 text-center py-3" v-if="!isLoadSubmissionAnswer && current?.submission_obj?.answer?.length == null">
                                            <div class="col-12 text-center">
                                                <img :src="'/images/empty.png'" alt="" width="300">
                                                <h1 class="text-muted">Data don't exist</h1>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="current?.submission_obj?.answer?.length && !isLoadSubmissionAnswer">
                                            <div class="row">
                                                <div class="col-12 mb-4">
                                                    <div class="card d-block w-100 border-0 shadow-lg rounded-lg overflow-hidden">  
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-12 mb-2">
                                                                    <h4>Questions</h4>
                                                                </div>
                                                                <div class="col-12">
                                                                    <button v-for="(item, i) in current.submission_obj.answer" :key="i" @click="answer = i" :class="{'btn-success' : item.score != null && answer == i, 'btn-outline-success' : item.score != null && answer != i, 'btn-outline-danger' : item.score == null && answer != i, 'btn-danger' : item.score == null && answer == i}" class="btn w50 h50 col mr-2 mb-2 ">{{i + 1}}</button>
                                                                </div>
                                                                <div class="col-12 text-center py-3" v-if="isStillLoadingSubmissionAnswer">
                                                                    <div>
                                                                        <div class="spinner-grow text-warning mx-3" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                        <div class="spinner-grow text-warning mx-3" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                        <div class="spinner-grow text-warning mx-3" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-4">
                                                    <div class="card border-0 shadow-lg rounded-lg p-0">
                                                        <div class="row" v-for="(item, index) in current.submission_obj.answer" :key="index">
                                                            <div class="col-12" v-show="answer == index">
                                                                <div class="card-body p-0">
                                                                    <div :id="'accordion1-'+index" class="accordion mb-0">
                                                                        <div class="card shadow-xss mb-0">
                                                                            <div class="card-header border-radius-none bg-current" :id="'heading1-'+index">
                                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link text-white collapsed" data-toggle="collapse" :data-target="'#collapse1-'+index" aria-expanded="false" :aria-controls="'collapse1-'+index">Question - {{index+1}}</button></h5>
                                                                            </div>
                                                                            <div :id="'collapse1-'+index" class="collapse" :aria-labelledby="'heading1'+index" :data-parent="'#accordion1-'+index">
                                                                                <div class="card-body text-center" v-if="item.quest.quest_type == 1">
                                                                                    <video class="w-50 h-auto" controls>
                                                                                        <source :src=" item.quest.path" type="video/mp4">
                                                                                    </video>
                                                                                </div>
                                                                                <div class="card-body" v-if="item.quest.quest_type == 2">
                                                                                    <audio class="w-100" controls>
                                                                                        <source :src=" item.quest.path" type="audio/mpeg">
                                                                                    </audio>
                                                                                </div>
                                                                                <div class="card-body ql-editor">
                                                                                    <div v-html="item.quest.question"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body p-0">
                                                                    <div :id="'accordion2-'+index" class="accordion mb-0">
                                                                        <div class="card shadow-xss mb-0">
                                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading-2'+index">
                                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse2-'+index" aria-expanded="false" :aria-controls="'collapse2-'+index">Answer</button></h5>
                                                                            </div>
                                                                            <div :id="'collapse2-'+index" class="collapse" :aria-labelledby="'heading2-'+index" :data-parent="'#accordion2-'+index">                
                                                                                <div v-if="item.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                                                                    <a :href=" item.answer" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>File Answer</a>
                                                                                </div>
                                                                                <div v-if="item.quest.submission_type == 2" style="width: 100wh" class="card-body ql-editor p-3 border-top bg-lightgrey">
                                                                                    <div v-html="item.answer"></div>
                                                                                </div>
                                                                                <div v-if="item.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                                    <table class="table m-0">
                                                                                        <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                                            <td width="5%" v-if="opt.id == item.answer && item.quest.answer == item.answer" class="bg-success text-white"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                                            <td width="5%" v-else-if="opt.id == item.answer && item.quest.answer != item.answer" class="bg-danger"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                                            <td width="5%" v-else class="bg-grey text-success"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                                            <td>{{opt.option}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                                <div v-if="item.quest.submission_type == 4" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                                                                    <a href="javascript:void(0)" :class="{'btn-success' : item.answer == '1', 'btn-outline-success' : item.answer != '1'}" class="btn btn-lg mr-2">True<i class="fas ml-2" :class="{'fa-check': '1' == item.quest.answer, 'fa-times': '1' != item.quest.answer}"></i></a>
                                                                                    <a href="javascript:void(0)" :class="{'btn-danger' : item.answer == '0', 'btn-outline-danger' : item.answer != '0'}" class="btn btn-lg">False<i class="fas ml-2" :class="{'fa-check': '0' == item.quest.answer, 'fa-times': '0' != item.quest.answer}"></i></a>
                                                                                </div>
                                                                                <div v-if="item.quest.submission_type == 5" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                                    <table class="table m-0">
                                                                                        <tr v-for="(opt, j) in item?.answer?.split('*##*')" :key="j">
                                                                                            <td style="width: 1%;" class="bg-grey"><b>[BLANK]-{{j}}</b></td>
                                                                                            <td>{{item.quest.answer.split('*##*')[j]}}</td>
                                                                                            <td class="bg-grey">{{opt}}</td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                                <div v-if="item.quest.submission_type == 6" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                                    <table class="table m-0">
                                                                                        <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                                            <td class="bg-grey">{{opt.option}} ({{item.quest.answer.split('*##*')[j]}})</td>
                                                                                            <td>{{item?.answer?.split('*##*')[j]}}</td>
                                                                                            <td width="1%"><i class="fas ml-2" :class="{'fa-check text-success': item?.answer?.split('*##*')[j] == item.quest.answer.split('*##*')[j], 'fa-times text-danger': item?.answer?.split('*##*')[j] != item.quest.answer.split('*##*')[j]}"></i></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                                <div v-if="item.quest.submission_type == 7" style="width: 100wh" class="card-body ql-editor border-top bg-lightgrey">
                                                                                    <div v-html="item.answer"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body p-0">
                                                                    <div :id="'accordion3-'+index" class="accordion mb-0">
                                                                        <div class="card shadow-xss mb-0">
                                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading-3'+index">
                                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse3-'+index" aria-expanded="false" :aria-controls="'collapse3-'+index">Feedback</button></h5>
                                                                            </div>
                                                                            <div :id="'collapse3-'+index" class="collapse" :aria-labelledby="'heading3-'+index" :data-parent="'#accordion3-'+index">                
                                                                                <div class="card-body p-0">
                                                                                    <div class="row">
                                                                                        <div class="col-12 px-lg-2">
                                                                                            <div class="card bg-success border-0">
                                                                                                <div class="card-header bg-success p-3">
                                                                                                    <h3 class="fw-600 text-white m-0">Rubric : {{item.rubrics.name}}</h3>
                                                                                                </div>
                                                                                                <div class="card-body bg-darkgrey py-0 px-1">
                                                                                                    <table class="table bg-white table-hover table-striped table-bordered mb-0 w-100">
                                                                                                        <tbody>
                                                                                                            <tr v-for="(crt, i) in item.rubrics.criteria" :key="i" class="p-auto">
                                                                                                                <td width="80%" valign="middle" class="fw-600 pt-lg-3">
                                                                                                                    <h1>
                                                                                                                        {{crt.name}}
                                                                                                                    </h1><small>{{crt.description}}</small>
                                                                                                                </td>
                                                                                                                <td width="20%"><div class="btn btn-success btn-block font-weight-bold">{{crt.submit_score.score}} / {{crt.score}}</div></td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="form-group col-12 px-4">
                                                                                            <label for="">Message</label>
                                                                                            <!-- <vue-editor v-model="item.message"/> -->
                                                                                            <div v-if="item.message" v-html="item.message"></div>
                                                                                            <p v-else class="text-muted m-0">No message!</p>
                                                                                            <hr>
                                                                                        </div>
                                                                                        <div class="form-group col-12 px-4">
                                                                                            <label for="">Attachment</label><br>
                                                                                            <a v-if="item.path" :href=" item.path" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>Current File</a>
                                                                                            <p v-else class="text-muted m-0">No attachment!</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card border-0 shadow-lg rounded-lg py-2 px-3 mb-2">
                                                        <div class="card-body">
                                                            <div v-if="current?.submission_obj?.comment?.length" class="row mt-3">
                                                                <div style="height: calc(63vh - 100px);" class="scrollable col scroll-bar border-0">
                                                                    <div class="row my-1 px-1 message-user" v-for="(item, n) in current.submission_obj.comment" :key="n">
                                                                        <figure class="avatar p-1">
                                                                            <img :src="item.user.photo ? item.user.photo : '/images/profile.png'" alt="image" style="width: 40px; height: 40px;">
                                                                        </figure>
                                                                        <div class="col pt-1">
                                                                            <h5 class="font-xssss mt-1 float-right">{{item.created_at | formatDate}}</h5>
                                                                            <h3 class="fw-700 mb-0">
                                                                                <p class="font-xsss text-grey-900 text-dark d-block mb-1">{{item.user.name}}</p>
                                                                            </h3>
                                                                            <span class="font-xsss text-grey-700 d-block">{{item.message}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else class="row">
                                                                <div class="col-12">
                                                                    <div class="row mt-3">
                                                                        <div class="col-12 text-center">
                                                                            <img :src="'/images/empty.png'" alt="" width="250">
                                                                            <h3 class="text-muted">Comment is Empty!</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <form @submit.prevent="postComment()" class="row justify-content-between p-0 mt-3 message-user">
                                                                <div class="col-10 m-0 p-0">
                                                                    <input type="text" v-model="message" placeholder="Add a comment...." class="form-control w-100 py-0" required>
                                                                </div>
                                                                <div class="col-2 m-0 p-0 pl-lg-3">
                                                                    <button type="submit" class="btn btn-current btn-block btn-lg rounded"><i class="fas fa-paper-plane font-xs"></i></button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-else>
                    <div class="card rounded-lg border-0 py-2 px-3 mb-2">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Masterfeedback Empty</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card rounded-lg border-0 mb-2">
                                <div style="height: calc(100vh * 1);" class="section full my-1 py-2 px-3 scroll-bar">
                                    <div class="row mb-2" v-for="(item, n) in assignments" :key="n">
                                        <div class="col-lg-12">
                                            <div class="row pt-1">
                                                <div class="col-3">
                                                    <button v-if="item.submission_obj != null && item.submission_obj?.status == 1" @click="setCurrent(item)" class="btn btn-lg btn-block btn-current"><i class="m-auto fas fa-tasks"></i></button>
                                                    <button v-else-if="item.submission_obj != null && item.submission_obj?.status == 0" @click="setCurrent(item)" class="btn btn-lg btn-block btn-current"><i class="m-auto fas fa-tasks"></i></button>
                                                    <div v-else class="btn btn-lg btn-block bg-current" ><i class="m-auto fas fa-tasks text-white"></i></div>
                                                </div>
                                                <div class="col">
                                                    <h4 class="fw-600">
                                                        {{item.type == '1' ? 'Discussion' : item.type == '2' ? 'Quiz' : item.type == '3' ? 'Individual Task' :item.type == '4' ? 'Group Task' : item.type == '5' ? 'Achievement Test' : ''}} - {{item.title}} 
                                                        <div v-if="item.submission == null">
                                                            <span v-if="compareDatesGreater(item.due_date)" class="badge badge-primary text-white">On Track</span>
                                                            <span v-else class="badge badge-danger">Missing</span>
                                                        </div>
                                                        <div v-else>
                                                            <span class="badge badge-success" v-if="item.submission_obj?.status == 1 && item.submission_obj.on_time == 1">Done</span>
                                                            <span class="badge badge-warning text-white" v-if="item.submission_obj?.status == 1 && item.submission_obj.on_time == 2">Done Late</span>
                                                            <span class="badge badge-warning text-white" v-if="item.submission_obj?.status == 0">On Track</span>
                                                        </div>
                                                    </h4>
                                                    <h5 class="font-xssss text-grey-500 fw-500">{{item.due_date | formatDate}}</h5>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center py-3" v-if="isLoadingAssignments">
                                        <div class="my-3">
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning mx-3" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="assignments.length == 0 && !isLoadingAssignments">
                <div class="col-12 text-center">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
        </div>
        <div class="col-12 text-center py-3" v-else>
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
export default {
    name: `MyCourseFeedback`,
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            answer: '',
            cycles: [],
            cycle: '',
            assignment:{},
            assignments: [],
            isLoadingAssignments: true,
            current: null,
            message: '',
            isLoadSubmissionAnswer: false,
            isStillLoadingSubmissionAnswer: false,
            media: process.env.VUE_APP_URL_CLOUD,
        }
    },
    created() {
        this.getCycles()
    },
    filters: {
        numfor: function(x) {
            return parseFloat(x).toFixed(2);
        }
    },
    watch: {
        assignments:function() {
            if(this.current == null && this.assignments.length > 0){
                this.setCurrent(this.assignments[0])
            }
        }
    },
    methods: {
        changeCycle(){
            this.isLoad = true
            this.assignment = {}
            this.current = null
            this.getFeedback()
        },
        async setCurrent(item){
            this.isLoadSubmissionAnswer = true
            this.current = item
            if(this.current.submission_obj?.id){
                await this.getSubmissionAnswer(this.current.submission_obj.id)
            }
            this.isLoadSubmissionAnswer = false
        },
        async getCycles(){
            if(localStorage.getItem(`${this.paramsId}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.paramsId}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.paramsId}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.paramsId}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.paramsId}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
            this.getAssignments()
        },
        async getSubmissionAnswer(id){
            let queryLimit = 10
            let queryOffset = 0
            this.current.submission_obj.answer = []
            this.isStillLoadingSubmissionAnswer = true
            while(this.isStillLoadingSubmissionAnswer){
                console.log('CALLING')
                await axios.get(`${process.env.VUE_APP_URL_API}/core/feedback/v2/submission?submission_id=${id}&limit=${queryLimit}&offset=${queryOffset}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.data.length == 0){
                        this.isStillLoadingSubmissionAnswer = false
                    }else{
                        this.current.submission_obj.answer = [...this.current.submission_obj.answer, ...res.data.data]
                        queryOffset = queryOffset + queryLimit
                        this.isLoadSubmissionAnswer = false
                    }
                })
            }
        },
        async postComment(){
            var data = {
                submission_id: this.current.submission_obj.id,
                message: this.message,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/feedback/comment`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.getFeedback(this.current.submission_obj.id)
                    this.message = ''
                    this.$swal({
                        toast: true,
                        title: 'Comment !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Comment !',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Comment !',
                        text: err.response.data.message[0],
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        compareDatesGreater(d1, d2 = null) {
            let date1 = moment(d1).format('YYYY-MM-DD H:m');
            let date2 = moment().format('YYYY-MM-DD H:m');
            if (d2) {
                date2 = new Date(d2).getTime();
            }
            if (date1 > date2) {
                return true;
            } else {
                return false;
            }
        },
        async getAssignments(){
            let queryLimit = 10
            let queryOffset = 0
            this.assignments = []

            while(this.isLoadingAssignments){
                await axios.get(`${process.env.VUE_APP_URL_API}/core/assignment/v2/list?slug=${this.paramsId}&cycle=${this.cycle}&limit=${queryLimit}&offset=${queryOffset}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.data.length == 0){
                        this.isLoadingAssignments = false
                    }else{
                        this.assignments = [...this.assignments, ...res.data.data]
                        queryOffset = queryOffset + queryLimit
                    }
                    this.isLoad = false
                })
            }
            
        }
    }
}
</script>
<style scoped>
.scrollable{
    height: calc(70vh - 150px);
}
.scrollable-1{
    height: calc(160vh - 200px);
}
::v-deep .ql-editor img {
    width: 100% !important;
}
</style>